import { utils } from '../../utils';
import window from '@wix/photography-client-lib/dist/src/sdk/windowWrapper';
import ResizeObserver from 'resize-observer-polyfill';

export default class DimensionsHelper {
  constructor(parent, props) {
    this.container = {};
    this.domId = '';
    this._cache = {};
    this.lastPropsJson = '';

    this.parent = parent;
    this.update(props);
  }

  getOrPutInCache(field, createValue) {
    if (this._cache[field]) return this._cache[field];
    this._cache[field] = createValue();
    return this._cache[field];
  }

  dumpCache() {
    this._cache = {};
  }

  update(props) {
    const { dimensions, id } = props;
    const propsJson = JSON.stringify({ dimensions, id });
    if (this.lastPropsJson !== propsJson) {
      this.lastPropsJson = propsJson;
      this.domId = id || this.domId;
      this.dimensions = dimensions || this.dimensions;
      this.measureContainer();
    }
    this.observe();
  }

  createResizeObserver() {
    this.resizeObserver = new ResizeObserver(() => {
      this.measureContainer();
    });
    this.observe();
  }

  observe() {
    if (this.observing || !this.resizeObserver) {
      return;
    }
    const galleryWrapperElement = window.document.getElementById(
      `gallery-wrapper-${this.domId}`,
    );
    if (galleryWrapperElement) {
      this.observing = true;
      this.resizeObserver.observe(galleryWrapperElement);
    }
  }

  measureContainer() {
    this.dumpCache();
    this.container =
      this.fixContainerIfNeeded(this.dimensions) || this.container;
    const container = this.getGalleryDimensions();

    if (
      JSON.stringify(container) !== JSON.stringify(this.parent.state.container)
    ) {
      this.parent.setState({ container });
    }
  }

  old_measureContainer() {
    const {
      id,
      dimensions: { width, height },
    } = this.galleryWrapperProps;

    let scrollBase = 0;
    let _width = width;
    const hasExactWidth = String(parseInt(width)) === String(width);
    const setWidthIfNeeded = w =>
      (_width = hasExactWidth ? width : parseInt(w));
    if (utils.isSSR()) {
      setWidthIfNeeded('');
    } else {
      try {
        const rect = window.document
          .getElementById(`gallery-wrapper-${id}`)
          .getBoundingClientRect();
        setWidthIfNeeded(rect.width);
        scrollBase = rect.y;
      } catch (e) {
        setWidthIfNeeded('');
      }
    }
    scrollBase = Math.max(0, scrollBase);
    return { scrollBase, width: _width, height };
  }

  isUnknownWidth(container = this.container) {
    return this.getOrPutInCache('isUnknownWidth', () => {
      //if the container width is not a number, it is fullwidth (e.g.: "", "100%", "calc(100% + -160px)")
      return (
        container &&
        String(parseInt(container.width)) !== String(container.width)
      );
    });
  }

  fixContainerIfNeeded(dimensions) {
    const isUnknownWidth = this.isUnknownWidth(dimensions);
    if (isUnknownWidth) {
      const _dimensions = { ...dimensions };
      const calcWidth = this.getBoundingRect().width;
      _dimensions.width = calcWidth;
      return _dimensions;
    } else {
      return dimensions;
    }
  }

  calcBoundingRect() {
    if (utils.isVerbose()) {
      console.count('calcBoundingRect');
    }
    try {
      return window.document
        .getElementById(`gallery-wrapper-${this.domId}`)
        .getBoundingClientRect();
    } catch (e) {
      return false;
    }
  }

  getBoundingRect() {
    return this.getOrPutInCache('boundingRect', () => {
      return (
        this.calcBoundingRect() || {
          x: 0,
          y: 0,
          width: Math.ceil(window.innerWidth),
          height: Math.ceil(window.innerHeight),
        }
      );
    });
  }

  calcBodyBoundingRect() {
    if (utils.isVerbose()) {
      console.count('calcBodyBoundingRect');
    }
    try {
      return window.document.body.getBoundingClientRect();
    } catch (e) {
      return false;
    }
  }

  getBodyBoundingRect() {
    return this.getOrPutInCache('bodyBoundingRect', () => {
      return (
        this.calcBodyBoundingRect() || {
          x: 0,
          y: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        }
      );
    });
  }

  calcScrollBase() {
    return this.getOrPutInCache('scrollBase', () => {
      let { scrollBase } = this.container;
      try {
        if (!(scrollBase >= 0)) {
          scrollBase = 0;
        }
        const offset = this.getBoundingRect().y - this.getBodyBoundingRect().y; //clientRect are relative to the viewport, thus affected by scroll and need to be normalized to the body
        if (offset >= 0) {
          scrollBase += offset;
        }
      } catch (e) {
        //
      }
      return Math.ceil(scrollBase);
    });
  }

  getGalleryDimensions() {
    return this.getOrPutInCache('galleryDimensions', () => {
      const res = {
        avoidGallerySelfMeasure: this.parent.avoidGallerySelfMeasure,
        galleryWidth: Math.ceil(this.getGalleryWidth()),
        galleryHeight: Math.ceil(this.getGalleryHeight()),
        scrollBase: Math.ceil(this.calcScrollBase()),
        height: Math.ceil(this.container.height),
        width: Math.ceil(this.container.width),
        documentHeight: Math.ceil(window.document.body.scrollHeight),
        windowWidth: Math.ceil(window.innerWidth),
      };
      return res;
    });
  }

  getGalleryWidth() {
    return this.getOrPutInCache('galleryWidth', () => {
      const domWidth = () =>
        utils.isSSR() ? '' : Math.ceil(this.getBoundingRect().width);
      return this.container.width > 0
        ? Math.ceil(this.container.width)
        : domWidth();
    });
  }

  getGalleryHeight() {
    return this.getOrPutInCache('galleryHeight', () => {
      const domHeight = () =>
        utils.isSSR() ? '' : Math.ceil(this.getBoundingRect().height);
      return this.container.height > 0
        ? Math.ceil(this.container.height)
        : domHeight();
    });
  }
}
